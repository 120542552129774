const AppConfig = {
    'api_base_url': 'https://keys.api.app.7x.ax/',
    'oauth_client': '7x-app',
    //'api_base_url': 'http://localhost/',
    //'oauth_client': 'm1e2e3z4a5a6n7-7x-localhost-app',


    'plans': [
        {
            name: "2x",
            description: "£2.50 / month & 2 requests / second"
        },
        {
            name: "3x",
            description: "£3.50 / month & 3 requests / second"
        },
        {
            name: "4x",
            description: "£7.50 / month & 7 requests / second"
        },
        {
            name: "5x",
            description: "£10.50 / month & 10 requests / second"
        },
        {
            name: "6x",
            description: "£22.50 / month & 25 requests / second"
        },
        {
            name: "7x",
            description: "£42.50 / month & 50 requests / second"
        },
    ]
}

export default AppConfig;


