import Keycloak from "keycloak-js";
import AppConfig from "../AppConfig";
const keycloak = new Keycloak({
    url: "https://id.mamluk.net/auth",
    realm: "mamluk",
    clientId: AppConfig.oauth_client,
});

export default keycloak;


